<template>
	<div v-if="user.isMe && !isEmpty" class="content-pad head">
		<FormButton :level="1" value="New Room" @click="showDialogClusterCreate('room')" />
	</div>
	<div id="gallery-empty" v-if="isEmpty" :class="{ 'left-align': user.isMe }">
		<template v-if="user.isMe">
			<h3>You haven't created any rooms yet.</h3>
			<p>Rooms are digital exhibition spaces that you can publish and share.</p>
			<br /><br />
			<FormButton :level="1" value="Create Room" @click="showDialogClusterCreate('room')" />
		</template>
		<template v-else>{{ user.name }} hasn't published any rooms yet.</template>
	</div>
	<RoomList :rooms="user.rooms" :leftAlign="true" :isMe="user.isMe" />
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'
import RoomList from '@/components/RoomList'

export default {
	name: 'UserRooms',
	components: {
		FormButton,
		RoomList,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isEmpty() {
			return !this.user.rooms || !this.user.rooms.length
		},
	},
	methods: {
		updateRooms() {
			// Update rooms list
		},

		showDialogClusterCreate(type) {
			const dialogStore = useDialogStore()
			dialogStore.dialogByName('DialogClusterCreate', {
				type,
				onConfirm: this.updateRooms,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.content-pad {
	margin-bottom: 0.4rem;
}
</style>
